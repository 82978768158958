import React, { useEffect, useState } from 'react';
import './App.css';
import TOPICOS from './topicos.json';
import RESPOSTAS from './respostas.json';

function PerguntaLinha( {pergunta} ) {
  return (
    <li>
      <a class="link-offset-2" href={pergunta.nomeHtmlResp}>{pergunta.pergunta}</a>
    </li>
  )
}

function PerguntasLista( { perguntas, textoPesquisa } ) {
  const listaPerguntas = [];
  
  perguntas.forEach(pergunta => {
    if ((pergunta.resposta.toLowerCase().indexOf(textoPesquisa.toLowerCase()) > -1)
    ) {
      listaPerguntas.push(<PerguntaLinha
        pergunta={pergunta}
        key={pergunta.perguntaId}/>);
      }
  });
  
  return (
    <nav>
      <ul>{listaPerguntas}</ul>
    </nav>
  )
}

function TextoEmListaPerguntas({perguntas, textoPesquisa}) {
  let seAchou = false;
  let contador = 0;

  while (!seAchou && (contador < perguntas.length)) {
    if (perguntas[contador].resposta.toLowerCase().indexOf(
        textoPesquisa.toLowerCase()
        ) > -1) {
          seAchou = true;
        }
    else { contador++ }
  }
  return (seAchou)
}

function TopicoTitulo ( {topico} ) {
  return (
    <p style={{ color: 'blue', fontSize: '22px', fontFamily: 'cursive'}}>{topico}</p>
  )
}

function TopicoBloco ( {topico, textoPesquisa} ) {
  return (
    <div>
      <TopicoTitulo topico={topico.topico}/>
      <PerguntasLista 
        perguntas={topico.perguntas}
        textoPesquisa={textoPesquisa}/>
    </div>
  )
}

function BlocosTabela ( {topicos, textoPesquisa} ) {
const listaTopicos = [];

topicos.forEach(topico => {
  const perguntas = topico.perguntas;
  if (TextoEmListaPerguntas({perguntas, textoPesquisa})
  ) {
    listaTopicos.push(<TopicoBloco 
      topico={topico}
      key={topico.topicoId}
      textoPesquisa={textoPesquisa}/>);
  }
});

return (
  <div class="card text-bg-light mb-3" style={{maxwidth: '20rem'}}>
    <div className="grid-blocos">
      {listaTopicos}
    </div>
  </div>
)
}

function CaixaPesquisa({ textoPesquisa, onTextoPesquisaChange }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <form>
        <p style={{ color: 'blue', fontFamily: 'cursive'}}>
          Como podemos ajudar?
        </p>
        <input 
          type="text"
          value={textoPesquisa}
          placeholder="Pesquisar..."
          onChange={(e) => onTextoPesquisaChange(e.target.value)}
        />
      </form>
    </div>
  )
}

function Cabecalho() {
  return (
    <p style={{ color: 'blue', fontSize: '50px', fontFamily: 'fantasy'
              , display: 'flex', justifyContent: 'center'}}>
      <img src="G.png" alt="Logo Geous" height="100"/>
      GERP FAQ
    </p>
  )
}

function FaleConosco() {
  return (
    <div>
      <p style={{ color: 'blue', fontFamily: 'cursive'
                , display: 'flex', justifyContent: 'center' }}>
        Não encontrou resposta para sua dúvida?
      </p>
      <p style={{ color: 'blue', textDecoration: 'underline'
                , fontFamily: 'cursive', display: 'flex'
                , justifyContent: 'center'}}>
        <a href="mailto:contato@geous.com.br">Fale Conosco</a>
      </p>
      <a href="mailto:contato@geous.com.br"
         style={{display: 'flex', justifyContent: 'center', fontStyle: ''}} >
        <img src="icone-mail.png" alt="Ícone e-mail" width="50px"/>
      </a>
    </div>
  )
}

function Rodape() {
  return (
    <FaleConosco/>
  )
}

function ObterTextoHtmlResposta(pergunta, respPadrao) {
  const [textoHtml, setTextoHtml] = useState('');

  useEffect(() => {
    const readFile = async () => {
      if (pergunta.nomeHtmlResp) {
        try {
          const response = await fetch(pergunta.nomeHtmlResp);
          const text = await response.text();
          if (text.indexOf(`PerguntaId${String(pergunta.perguntaId)}`) > -1) {
            setTextoHtml(text);
          }
          else {
            setTextoHtml(respPadrao);
          };
        } catch (erro) {
          console.log(`Não foi possível carregar ${pergunta.nomeHtmlResp}`);
        }
      }
      else {
        setTextoHtml(respPadrao);
      };
    };
    readFile();
  }, [pergunta, respPadrao]);

  return (textoHtml);
}

function AtribuirRespostas(topicos, respostas) {
  const topicosRespondidos = [];
  
  topicos.forEach(t => {
    t.perguntas.forEach(p => {
      const respPadrao = respostas.find(r => r.perguntaId === p.perguntaId).resposta;
      p.resposta = ObterTextoHtmlResposta(p, respPadrao);
    });
    topicosRespondidos.push(t);
  });

  return (topicosRespondidos);
};

function GerpFAQ() {
  const [textoPesquisa, setTextoPesquisa] = useState('');
  const topicosRespondidos = AtribuirRespostas(TOPICOS,RESPOSTAS);
  
  return (
    <>
      <Cabecalho/>
      <CaixaPesquisa 
        textoPesquisa={textoPesquisa}
        onTextoPesquisaChange={setTextoPesquisa}/>
      <BlocosTabela 
        topicos={topicosRespondidos}
        textoPesquisa={textoPesquisa}/>
      <Rodape/>
    </>
  )
}

export default function App() {
  return (
    <GerpFAQ />
  );
}

